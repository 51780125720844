// @include font-face("Muller", "../fonts/MullerRegular", 400, normal);
// @include font-face("Muller", "../fonts/MullerRegular", 400, normal);
@include font-face("Montserrat", "../fonts/Montserrat-Regular", 400, normal);
@include font-face("MontserratMedium", "../fonts/Montserrat-Medium", 500, normal);
@include font-face("MontserratSemiBold", "../fonts/Montserrat-SemiBold", 600, normal);
@include font-face("MontserratBold", "../fonts/Montserrat-Bold", 700, normal);
// @include font-face("CoFoSansRegular", "../fonts/CoFoSansRegular", 400, normal);
// @include font-face("CoFoSansMedium", "../fonts/CoFoSans-Medium", 500, normal);

// @include font-face("Stem", "../fonts/Stem-Regular", 400, normal);
// @include font-face("Stem-Medium", "../fonts/Stem-Medium", 500, normal);
// @include font-face("Stem-Bold", "../fonts/Stem-Bold", 700, normal);
