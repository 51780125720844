.partners {
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-bottom: 30px;
  }
  &__left {
    padding-top: 30px;
    h2 {
      font-size: 64px;
      font-weight: 300;
      max-width: 684px;
      margin-top: 0;
    }
  }
  &__right {
    padding-top: 30px;
    p {
      font-size: 20px;
      margin: 0;
    }
  }
  &__gridone {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
  &__gridtwi {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px;
    img {
      margin: 0 auto;
    }
    .reviews {
      text-align: center;
      h3 {
        font-size: 48px;
        font-family: "MontserratMedium", sans-serif;
        font-weight: 500;
        color: #323333;
        line-height: 80px;
      }
    }
  }
}
