.whoworks {
  background: #e1dcdb;
  padding: 45px 20px;
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  &__left {
    h2 {
      font-size: 24px;
      font-weight: 400;
    }
  }
  &__right {
    h2 {
      font-size: 64px;
      margin-top: 0;
      font-weight: 400;
      line-height: 68px;
    }
  }
  &__box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    &--item {
      font-size: 24px;
      border-bottom: 1px solid #323333;
      padding-bottom: 12px;
    }
  }
}
