.footer {
  &__container {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #323333;
    padding-top: 40px;
  }

  .logo {
    max-width: 600px;
  }

  &__contact {
    a {
      display: block;
      font-size: 16px;
      border-bottom: 1px solid #323333;
      padding-bottom: 8px;
      color: #323333;
      margin-bottom: 10px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      li {
        a {
          border-bottom: 0px solid #323333;
        }
      }
    }
  }
  &__menu {
    ul {
      column-count: 2;
      height: 200px;
      li {
        margin-bottom: 16px;
        width: 219px;
        border-bottom: 1px solid #323333;
        margin-right: 30px;
      }
    }

    a {
      color: #323333;
      font-size: 20px;
    }
  }
}
