.services {
  background: #e1dcdb;
  padding-bottom: 53px;
  padding-top: 53px;
  &__item {
    margin-bottom: 80px;
    position: relative;
    &--text {
      position: absolute;
      z-index: 99;
      color: #fff;
      padding: 32px 12px;
      top: 0;
      h2 {
        margin-top: 0;
        font-size: 40px;
      }
      p {
        font-size: 32px;
      }
      img {
        position: absolute;
        right: -120px;
        bottom: -110px;
      }
    }
  }
}
