.contact {
  padding-top: 80px;
  margin-bottom: 80px;

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    h2 {
      font-size: 40px;
      margin-top: 0;
      margin-bottom: 25px;
    }
    p {
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 30px;
    }
    a {
      font-size: 20px;
      display: inline-block;
      border: 1px solid #323333;
      padding: 22px 118px;
      color: #323333;
    }
    h3 {
      font-size: 40px;
      margin-top: 80px;
    }
  }
}
