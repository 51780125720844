@charset "UTF-8";
:root {
  --font-family: "Open Sans", sans-serif;
  --content-width: 1360px;
  --container-offset: 20px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --main-font: "Montserrat";
  --medium-font: "MontserratMedium";
  --semi-font: "MontserratSemiBold";
  --semi-font: "MontserratSemiBold";
  --bold-font: "MontserratBold";
}

/* stylelint-disable */ /* stylelint-disable */
.burger-close {
  position: absolute;
  right: var(--container-offset);
  top: 30px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  width: 44px;
  height: 44px;
  background-image: url("../img/close.svg");
  background-position: center;
  background-size: 14px 12px;
  background-repeat: no-repeat;
}

/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "MontserratMedium";
  src: url("../fonts/../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "MontserratSemiBold";
  src: url("../fonts/../fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("../fonts/../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--main-font, sans-serif);
  -webkit-text-size-adjust: 100%;
  text-rendering: auto;
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
}
.page ::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: #fff;
  color: #323333;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0;
  max-width: var(--container-width);
}
@media (max-width: 1280px) {
  .container {
    padding: 0 var(--container-offset);
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  padding: 12px 27px;
  border-radius: 33px;
  cursor: pointer;
  transition: all 0.5s ease;
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #1c1c22;
}
.btn span {
  font-size: 14px;
  color: #161513;
  font-family: var(--bold-font, sans-serif);
}

.fixed-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 980px) {
  .about .page__body {
    background: #e1dcdb;
  }
  .about .contact .container {
    border-top: 2px solid #323333;
    padding-top: 40px;
  }
  .about .about__block {
    margin-top: 100px;
  }
  .about .about__block h2 {
    font-size: 48px;
    font-weight: 400;
  }
  .about .about__block--box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-bottom: 50px;
  }
  .about .about__block--box:last-child {
    margin-bottom: 0;
  }
  .about .about__block--box p {
    font-size: 24px;
    line-height: 28px;
  }
  .about .about__block--box h2 {
    font-size: 48px;
  }
  .about .header__logo img {
    width: 320px;
  }
  .about .header .burger__icon span {
    display: block;
    width: 40px;
    height: 2px;
    margin-bottom: 7px;
    background-color: #323333;
  }
  .about .header .burger__icon span:last-child {
    margin: 0;
  }
  .about .header__right {
    margin-right: 0;
    display: flex;
    align-items: center;
  }
  .about .header__right a {
    font-size: 20px;
    color: #323333;
  }
  .about .whoworks {
    padding-top: 135px;
  }
  .projectsa .project {
    padding-top: 135px;
  }
  .projectsa .project__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  .projectsa .allproject {
    display: flex;
    justify-content: center;
  }
  .projectsa .allproject a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    display: inline-block;
    border: 1px solid #323333;
    padding: 25px 118px;
    font-size: 20px;
    font-family: "MontserratMedium", sans-serif;
    font-weight: 500;
    color: #323333;
    margin-bottom: 80px;
    text-align: center;
  }
  .projectsa .header__logo img {
    width: 320px;
  }
  .projectsa .header .burger__icon span {
    display: block;
    width: 40px;
    height: 2px;
    margin-bottom: 7px;
    background-color: #323333;
  }
  .projectsa .header .burger__icon span:last-child {
    margin: 0;
  }
  .projectsa .header__right {
    margin-right: 0;
    display: flex;
    align-items: center;
  }
  .projectsa .header__right a {
    font-size: 20px;
    color: #323333;
  }
}
@media (min-width: 980px) {
  .header {
    position: absolute;
    padding: 20px;
    width: 100%;
  }
  .header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header__burger {
    margin-right: auto;
    margin-left: 60px;
    position: relative;
    cursor: pointer;
  }
  .header .burger__icon span {
    display: block;
    width: 40px;
    height: 2px;
    margin-bottom: 7px;
    background-color: #fff;
  }
  .header .burger__icon span:last-child {
    margin: 0;
  }
  .header__right {
    margin-right: 0;
    display: flex;
    align-items: center;
  }
  .header__right a {
    font-size: 20px;
    color: #fff;
  }
  .header .nav {
    display: none;
    width: 360px;
    background-color: #f5f5f5;
    padding: 20px;
    right: 0;
    position: absolute;
    top: 35px;
  }
  .header .nav.expanded {
    display: block;
  }
  .header .nav a {
    color: #323333;
    font-style: 20px;
  }
  .header .nav li {
    padding-bottom: 8px;
    border-bottom: 1px solid #323333;
    margin-bottom: 10px;
  }
  .header .nav li:last-child {
    margin: 0;
  }
}
@media (min-width: 980px) {
  .hero {
    background-image: url("/img/bghero.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 715px;
    display: block;
    overflow: hidden;
  }
  .hero h1 {
    margin: 0;
    padding-top: 180px;
    font-size: 68px;
    color: #fff;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    max-width: 1260px;
    padding-left: 120px;
    letter-spacing: -2.72px;
    line-height: 82px;
  }
  .hero__last {
    margin-top: 144px;
    padding: 20px 0px;
    color: #fff;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    border-top: 2px solid #fff;
    padding-top: 20px;
  }
  .hero__left {
    display: flex;
  }
  .hero__left a {
    display: inline-block;
    border: 1px solid #fff;
    font-size: 20px;
    padding: 12px 20px;
    color: #fff;
    margin-top: 24px;
  }
  .hero__left--text {
    margin-left: 14px;
  }
  .hero__left--text h3 {
    font-size: 24px;
    font-family: "MontserratMedium", sans-serif;
    font-weight: 500;
    margin-bottom: 0x;
    margin-top: 0;
  }
  .hero__right {
    padding-right: 20px;
  }
  .hero__right p {
    font-size: 20px;
    max-width: 717px;
    line-height: 32px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-top: 0;
  }
}
.services {
  background: #e1dcdb;
  padding-bottom: 53px;
  padding-top: 53px;
}
.services__item {
  margin-bottom: 80px;
  position: relative;
}
.services__item--text {
  position: absolute;
  z-index: 99;
  color: #fff;
  padding: 32px 12px;
  top: 0;
}
.services__item--text h2 {
  margin-top: 0;
  font-size: 40px;
}
.services__item--text p {
  font-size: 32px;
}
.services__item--text img {
  position: absolute;
  right: -120px;
  bottom: -110px;
}

.project__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding-top: 30px;
}
.project__container h2 {
  font-size: 64px;
  color: #323333;
  line-height: 80px;
  margin: 0;
}
.project__right {
  margin-bottom: 80px;
}
.project__right p {
  font-size: 20px;
  color: #322233;
  line-height: 28px;
}
.project__right a {
  margin-top: 32px;
  display: inline-block;
  border: 1px solid #323333;
  padding: 25px 118px;
  font-size: 20px;
  font-family: "MontserratMedium", sans-serif;
  font-weight: 500;
  color: #323333;
}
.project__list {
  margin-bottom: 30px;
  overflow: hidden;
}
.project__list img {
  width: 100%;
}
.project__item {
  position: relative;
}
.project__item--text {
  position: absolute;
  background-color: #f5f5f5;
  z-index: 99;
  bottom: 3px;
  padding: 12px;
  font-size: 20px;
  width: 400px;
}
.project__item--text span {
  display: block;
  margin-bottom: 16px;
}
.project__item--text ul li {
  margin-bottom: 16px;
}
.project__item--text ul li span {
  display: inline-block;
  margin-right: 50px;
}

.whoworks {
  background: #e1dcdb;
  padding: 45px 20px;
}
.whoworks__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.whoworks__left h2 {
  font-size: 24px;
  font-weight: 400;
}
.whoworks__right h2 {
  font-size: 64px;
  margin-top: 0;
  font-weight: 400;
  line-height: 68px;
}
.whoworks__box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}
.whoworks__box--item {
  font-size: 24px;
  border-bottom: 1px solid #323333;
  padding-bottom: 12px;
}

.partners__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 30px;
}
.partners__left {
  padding-top: 30px;
}
.partners__left h2 {
  font-size: 64px;
  font-weight: 300;
  max-width: 684px;
  margin-top: 0;
}
.partners__right {
  padding-top: 30px;
}
.partners__right p {
  font-size: 20px;
  margin: 0;
}
.partners__gridone {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  text-align: center;
}
.partners__gridone img {
  margin: 0 auto;
}
.partners__gridtwi {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
}
.partners__gridtwi img {
  margin: 0 auto;
}
.partners__gridtwi .reviews {
  text-align: center;
}
.partners__gridtwi .reviews h3 {
  font-size: 48px;
  font-family: "MontserratMedium", sans-serif;
  font-weight: 500;
  color: #323333;
  line-height: 80px;
}

.contact {
  padding-top: 80px;
  margin-bottom: 80px;
}
.contact__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.contact__container h2 {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 25px;
}
.contact__container p {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 30px;
}
.contact__container a {
  font-size: 20px;
  display: inline-block;
  border: 1px solid #323333;
  padding: 22px 118px;
  color: #323333;
}
.contact__container h3 {
  font-size: 40px;
  margin-top: 80px;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #323333;
  padding-top: 40px;
}
.footer .logo {
  max-width: 600px;
}
.footer__contact a {
  display: block;
  font-size: 16px;
  border-bottom: 1px solid #323333;
  padding-bottom: 8px;
  color: #323333;
  margin-bottom: 10px;
}
.footer__contact ul {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.footer__contact ul li a {
  border-bottom: 0px solid #323333;
}
.footer__menu ul {
  column-count: 2;
  height: 200px;
}
.footer__menu ul li {
  margin-bottom: 16px;
  width: 219px;
  border-bottom: 1px solid #323333;
  margin-right: 30px;
}
.footer__menu a {
  color: #323333;
  font-size: 20px;
}