@media (min-width: 980px) {
  .header {
    position: absolute;
    padding: 20px;
    width: 100%;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__burger {
      margin-right: auto;
      margin-left: 60px;
      position: relative;
      cursor: pointer;
    }

    .burger__icon {
      span {
        display: block;
        width: 40px;
        height: 2px;
        margin-bottom: 7px;
        background-color: #fff;
        &:last-child {
          margin: 0;
        }
      }
    }

    &__right {
      margin-right: 0;
      display: flex;
      align-items: center;
      a {
        font-size: 20px;
        color: #fff;
      }
    }
    .nav {
      display: none;
      width: 360px;
      background-color: #f5f5f5;
      padding: 20px;
      right: 0;
      position: absolute;
      top: 35px;

      &.expanded {
        display: block;
      }

      a {
        color: #323333;
        font-style: 20px;
      }
      li {
        padding-bottom: 8px;
        border-bottom: 1px solid #323333;
        margin-bottom: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
