.project {
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-top: 30px;
    h2 {
      font-size: 64px;
      color: #323333;
      line-height: 80px;
      margin: 0;
    }
  }
  &__right {
    margin-bottom: 80px;
    p {
      font-size: 20px;
      color: #322233;
      line-height: 28px;
    }
    a {
      margin-top: 32px;
      display: inline-block;
      border: 1px solid #323333;
      padding: 25px 118px;
      font-size: 20px;
      font-family: "MontserratMedium", sans-serif;
      font-weight: 500;
      color: #323333;
    }
  }
  &__list {
    margin-bottom: 30px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  &__item {
    position: relative;
    &--text {
      position: absolute;
      background-color: #f5f5f5;
      z-index: 99;
      bottom: 3px;
      padding: 12px;
      font-size: 20px;
      width: 400px;
      span {
        display: block;
        margin-bottom: 16px;
      }
      ul {
        li {
          margin-bottom: 16px;
          span {
            display: inline-block;
            margin-right: 50px;
          }
        }
      }
    }
  }
}
