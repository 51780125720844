@media (min-width: 980px) {
  .hero {
    background-image: url("/img/bghero.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 715px;
    display: block;
    overflow: hidden;
    h1 {
      margin: 0;
      padding-top: 180px;
      font-size: 68px;
      color: #fff;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
      max-width: 1260px;
      padding-left: 120px;
      letter-spacing: -2.72px;
      line-height: 82px;
    }
    &__last {
      margin-top: 144px;
      padding: 20px 0px;
      color: #fff;
      position: relative;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      border-top: 2px solid #fff;
      padding-top: 20px;
    }

    &__left {
      display: flex;
      a {
        display: inline-block;
        border: 1px solid #fff;
        font-size: 20px;
        padding: 12px 20px;
        color: #fff;
        margin-top: 24px;
      }
      &--text {
        margin-left: 14px;
        h3 {
          font-size: 24px;
          font-family: "MontserratMedium", sans-serif;
          font-weight: 500;
          margin-bottom: 0x;
          margin-top: 0;
        }
      }
    }
    &__right {
      padding-right: 20px;
      p {
        font-size: 20px;
        max-width: 717px;
        line-height: 32px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        margin-top: 0;
      }
    }
  }
}
