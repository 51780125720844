// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Open Sans", sans-serif;
  --content-width: 1360px;
  --container-offset: 20px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;

  --main-font: "Montserrat";
  --medium-font: "MontserratMedium";
  --semi-font: "MontserratSemiBold";
  --semi-font: "MontserratSemiBold";
  --bold-font: "MontserratBold";
}
