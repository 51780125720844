html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--main-font, sans-serif);
  -webkit-text-size-adjust: 100%;
  text-rendering: auto;
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
    scroll-behavior: smooth;
  }
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: #fff;
  color: #323333;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0;
  max-width: var(--container-width);
  @media (max-width: 1280px) {
    padding: 0 var(--container-offset);
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  padding: 12px 27px;
  border-radius: 33px;
  cursor: pointer;
  transition: all 0.5s ease;
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #1c1c22;
  span {
    font-size: 14px;
    color: #161513;
    font-family: var(--bold-font, sans-serif);
  }
}

.fixed-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 980px) {
  .about {
    .page__body {
      background: #e1dcdb;
    }

    .contact {
      .container {
        border-top: 2px solid #323333;
        padding-top: 40px;
      }
    }

    .about__block {
      margin-top: 100px;
      h2 {
        font-size: 48px;
        font-weight: 400;
      }
    }

    .about__block--box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        font-size: 24px;
        line-height: 28px;
      }
      h2 {
        font-size: 48px;
      }
    }

    .header {
      &__logo {
        img {
          width: 320px;
        }
      }

      .burger__icon {
        span {
          display: block;
          width: 40px;
          height: 2px;
          margin-bottom: 7px;
          background-color: #323333;
          &:last-child {
            margin: 0;
          }
        }
      }

      &__right {
        margin-right: 0;
        display: flex;
        align-items: center;
        a {
          font-size: 20px;
          color: #323333;
        }
      }
    }
    .whoworks {
      padding-top: 135px;
    }
  }
  .projectsa {
    .project {
      padding-top: 135px;
      &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
      }
    }

    .allproject {
      display: flex;
      justify-content: center;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        display: inline-block;
        border: 1px solid #323333;
        padding: 25px 118px;
        font-size: 20px;
        font-family: "MontserratMedium", sans-serif;
        font-weight: 500;
        color: #323333;
        margin-bottom: 80px;
        text-align: center;
      }
    }

    .header {
      &__logo {
        img {
          width: 320px;
        }
      }

      .burger__icon {
        span {
          display: block;
          width: 40px;
          height: 2px;
          margin-bottom: 7px;
          background-color: #323333;
          &:last-child {
            margin: 0;
          }
        }
      }

      &__right {
        margin-right: 0;
        display: flex;
        align-items: center;
        a {
          font-size: 20px;
          color: #323333;
        }
      }
    }
  }
}
